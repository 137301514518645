import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Badge, Input } from 'reactstrap'
import InputBox from '../Form/InputBox'

import TooltipHosts from './ToolTip'

const HostsList = ({ hosts, loading, loaded }) => {
  const [isOpen, setOpen] = useState(false)
  const [filters, setFilters] = useState({
    search: '',
    filter: 'all',
    results: [],
  })

  const toggle = () => {
    setOpen(prev => !prev)
  }

  const handleFilters = e => {
    const { name, value } = e.target
    if (hosts && hosts.length) {
      if (name === 'search') {
        setFilters({
          ...filters,
          [name]: value,
          filter: 'all',
          results: hosts.filter(result => {
            const regex = new RegExp(value, 'gi')
            return result.name.match(regex)
          }),
        })
      } else if (name === 'filter') {
        let newHosts = []
        if (value === 'all') newHosts = hosts
        if (value === 'free')
          newHosts = hosts.filter(host => host.free.supported === 'yes')
        if (value === 'premium')
          newHosts = hosts.filter(host => host.vip.supported === 'yes')
        setFilters({
          ...filters,
          [name]: value,
          results: newHosts,
        })
      }
    }
  }

  return (
    <section className="domainfeatures white">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <h4 className="text-center">
              Supported Hosts List {'  '}
              <TooltipHosts isOpen={isOpen} toggle={toggle} />
            </h4>
          </div>
          <div className="col-sm-12  d-flex justify-content-end">
            <div className="col-4">
              <InputBox
                type="text"
                name="search"
                placeholder="Search for a host."
                value={filters.search}
                onChangeInput={handleFilters}
                error=""
              />
            </div>
            <div className="col-2">
              <Input
                type="select"
                name="filter"
                onChange={handleFilters}
                value={filters.filter}
              >
                <option value="all">All</option>
                <option value="free">Free Hosts</option>
                <option value="premium">Premium Hosts</option>
              </Input>
            </div>
          </div>
        </div>
        <div className="domains-table hostsList">
          <div className="row">
            <div className="col-sm-12">
              <Table bordered hover responsive>
                <thead>
                  <tr className="text-center">
                    <th>Filehost</th>
                    <th>Status</th>
                    <th>Premium Users</th>
                    <th>
                      <i className="fas fa-crown text-danger" /> Premium Users
                      <br />
                      FILESIZE / BANDWIDTH LIMIT{' '}
                      <span className="text-danger">[per day]</span>
                    </th>
                    <th>Free Users</th>
                    <th>
                      Free Users <br />
                      FILESIZE / BANDWIDTH LIMIT{' '}
                      <span className="text-danger">[per day]</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filters.search && filters.results <= 0 && (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No results found !
                      </td>
                    </tr>
                  )}
                  {/* eslint-disable */}
                  {loading &&
                    !loaded &&
                    [...Array(40)].map((_, index) => (
                      <tr key={String(index)}>
                        <td className="shine d-table-cell p-4"></td>
                        <td className="shine d-table-cell p-4"></td>
                        <td className="shine d-table-cell p-4"></td>
                        <td className="shine d-table-cell p-4"></td>
                        <td className="shine d-table-cell p-4"></td>
                        <td className="shine d-table-cell p-4"></td>
                      </tr>
                    ))}
                  {/* eslint-enable */}
                  {loaded &&
                    !loading &&
                    (filters.search || filters.filter !== 'all'
                      ? filters.results
                      : hosts
                    ).map(host => (
                      <tr key={host.name}>
                        <td>
                          <i
                            title={host.name}
                            className={`hosts_${
                              host.name && host.name.replace(/\./g, '_')
                            } pr-4`}
                          ></i>
                          {host.name}
                        </td>
                        <td>
                          {host.vip.supported === 'yes' ? (
                            <i className="fas fa-check text-success host-status" />
                          ) : (
                            <i className="fas fa-times text-danger host-status"></i>
                          )}
                        </td>
                        <td>
                          {host.vip.supported === 'yes' ? (
                            <i className="fas fa-check-circle text-success host-status"></i>
                          ) : (
                            <i className="fas fa-times-circle text-danger host-status"></i>
                          )}
                        </td>
                        <td>
                          {host.vip.supported === 'no' && (
                            <Badge color="warning" style={{ fontSize: '15px' }}>
                              Under Maintenance
                            </Badge>
                          )}
                          {host.vip.supported === 'yes' && (
                            <Badge
                              color="primary"
                              className="p-2"
                              style={{ fontSize: '15px' }}
                            >
                              Filesize: {host.vip.size_limit} / Bandwidth:{' '}
                              {host.vip.bandwidth_limit}
                            </Badge>
                          )}
                        </td>
                        <td>
                          {host.free.supported === 'yes' ? (
                            <i className="fas fa-check-circle text-success host-status"></i>
                          ) : (
                            <i className="fas fa-times-circle text-danger host-status"></i>
                          )}
                        </td>
                        <td>
                          {/* eslint-disable */}
                          {host.vip.supported === 'yes' &&
                            host.free.supported === 'no' && (
                              <Badge
                                color="warning"
                                style={{ fontSize: '15px' }}
                              >
                                Only for Premium Users
                              </Badge>
                            )}
                          {host.vip.supported === 'no' &&
                            host.free.supported === 'no' && (
                              <Badge style={{ fontSize: '15px' }}>
                                Not Supported
                              </Badge>
                            )}
                          {host.free.supported === 'yes' && (
                            <Badge
                              color="primary"
                              className="p-2"
                              style={{ fontSize: '15px' }}
                            >
                              Filesize: {host.free.size_limit} / Bandwidth:{' '}
                              {host.free.bandwidth_limit}
                            </Badge>
                          )}
                          {/* eslint-enable */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

HostsList.defaultProps = {
  hosts: [],
  loading: false,
  loaded: false,
}

HostsList.propTypes = {
  hosts: PropTypes.array,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
}

export default HostsList
