import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'reactstrap'

const TooltipHosts = ({ isOpen, toggle }) => (
  <>
    <i className="fas fa-info-circle text-info" id="host-info" />
    <Tooltip
      placement="right"
      isOpen={isOpen}
      target="host-info"
      toggle={toggle}
    >
      <div className="d-flex flex-column text-left">
        <div>1. All Limits are per day (24 hours) and per user.</div>
        <div>2. Bandwidth resets everyday at GMT+5:30.</div>
        <div>
          3. Bandwidth & Filesize limits changes time to time based on
          availability.
        </div>
      </div>
    </Tooltip>
  </>
)

TooltipHosts.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default TooltipHosts
