import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'

const InputBox = ({
  type,
  placeholder,
  onChangeInput,
  value,
  label,
  error,
  name,
  ...rest
}) => {
  const inputProps = {
    name,
    type,
    value,
    placeholder,
    onChange: onChangeInput,
    ...(error && { invalid: error !== null }),
    ...rest,
  }
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <FormGroup>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          {label && (
            <Label for={label} className="mr-sm-2">
              {label}
            </Label>
          )}
          <Input {...inputProps} />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          {error !== '' && (
            <FormFeedback className="d-block">{error}</FormFeedback>
          )}
        </div>
      </div>
    </FormGroup>
  )
}

InputBox.defaultProps = {
  error: null,
  label: '',
}

InputBox.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
}

export default memo(InputBox)
