import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import HostsAdded from '../components/HostStatus/HostsAdded'
import HostsList from '../components/HostStatus/HostsList'
import Adsense from '../components/Adsense'
import SEO from '../components/SEO'

import { getData } from '../reducers/app'
import { HOST_STATUS } from '../data/apis'
import { NEW_HOSTS } from '../data/hosts'

const HostStatusContainer = () => {
  const dispatch = useDispatch()
  const {
    hosts: { data, loading, loaded },
  } = useSelector(state => state.app)
  useEffect(() => {
    dispatch(getData(HOST_STATUS))
  }, [])
  return (
    <>
      <SEO page="hostStatus" />
      <HostsAdded
        title="Recently Added Hosts!"
        hosts={NEW_HOSTS}
        shouldAnimate={false}
      />
      <HostsList hosts={data} loaded={loaded} loading={loading} />
      <Adsense slot="6222161092" format="responsive" />
    </>
  )
}

export default HostStatusContainer
